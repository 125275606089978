/* Import Stylesheets */
import './App.css';

/* Import Pages */
import Home from './pages/home/Home';
import CoventGarden from './pages/covent-garden/CoventGarden';
import Contact from './pages/contact/Contact';

/* Import Modules */
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useEffect } from 'react';
import ReactGA from 'react-ga';


function App() {
    useEffect(() => {
        console.log("HI");
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);
    ReactGA.initialize('G-TEG27YN3LD');

    return (
        <Router>
            <Routes>
                <Route path="/contact" element={<Contact />} />
                <Route path="/coventgarden" element={<CoventGarden />} />
                <Route path="/" element={<Home />} />
            </Routes>
        </Router>
    );
}

export default App;
