/* Import Stylesheet */
import "./Contact.css";

/* Import Modules */
import ReactGA from 'react-ga';

function Contact() {
    ReactGA.pageview(window.location.pathname + window.location.search);
    return (<div>
        Hello World (Contact)
    </div>)
}

export default Contact;