/* Import Stylesheet */
import "./CoventGarden.css";

/* Import Modules */
import { Link } from "react-router-dom";
import { useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookSquare, faTwitterSquare, faTiktok, faSnapchatSquare, faYoutube, faYoutubeSquare, faInstagramSquare, faInstagram } from '@fortawesome/free-brands-svg-icons'
import { faAddressBook, faAddressCard, faEnvelope, faMoneyBillAlt } from "@fortawesome/free-regular-svg-icons"
import { faEnvelopeSquare, faMailBulk, faMoneyBillWave, faMoneyBillWaveAlt } from "@fortawesome/free-solid-svg-icons";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import { Formik } from 'formik';
import ReactGA from 'react-ga';

function CoventGarden() {

    const [showMailingListModal, setShowMailingListModal] = useState(false);
    const handleClose = () => setShowMailingListModal(false);
    const handleShow = () => {
        ReactGA.event({
            category: 'CoventGarden',
            action: 'OpenMailingListModal',
            label: 'Join My Inner Circle'
        });
        setShowMailingListModal(true);
    }

    let submitted = false;
    return (
        <div className="page-container">

            <div></div>


            <div className="center-container">

                {/* Title */}
                <div className="heading">LUCA<span className="highlight">MUSIC</span>UK</div>

                {/* Links */}
                <div className="d-flex flex-column w-100">
                    <Dropdown>
                        <Dropdown.Toggle variant="secondary" className="button w-100">
                            Donate to Luca
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="dropdown-menu">
                            <Dropdown.Item href="https://buy.stripe.com/7sIfZL94P3po9xK8wA" target="_blank" className="dropdown-menu-item">Donate £50</Dropdown.Item>
                            <Dropdown.Item href="https://buy.stripe.com/00gbJv5SDf869xK28d" target="_blank" className="dropdown-menu-item">Donate £30</Dropdown.Item>
                            <Dropdown.Item href="https://buy.stripe.com/4gwaFrep9e429xKbIL" target="_blank" className="dropdown-menu-item">Donate £20</Dropdown.Item>
                            <Dropdown.Item href="https://buy.stripe.com/bIYfZLgxhaRQ25ieUW" target="_blank" className="dropdown-menu-item">Donate £10</Dropdown.Item>
                            <Dropdown.Item href="https://buy.stripe.com/7sIaFr6WHbVUcJW289" target="_blank" className="dropdown-menu-item">Donate £5</Dropdown.Item>
                            <Dropdown.Item onClick={() => {
                                navigator.clipboard.writeText('1GkVCiC2N1eD32wNKCsvqE2UPTnPkueWJn');
                                alert("BTC address copied to clipboard.")
                            }} target="_blank" className="dropdown-menu-item">Copy Bitcoin Address to Clipboard</Dropdown.Item>
                            <Dropdown.Item onClick={() => {
                                navigator.clipboard.writeText('0x2d099676840f48c6eccbeedd5f338951ebe7eb83');
                                alert("ETH address copied to clipboard.")
                            }} target="_blank" className="dropdown-menu-item">Copy Ethereum Address to Clipboard</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
                <div className="w-100">
                    <Row>
                        <Col xs={12} lg={12}>
                            <a href="https://instagram.com/lucamusicuk" className="standard-btn standard-btn-instagram mt-3 w-100"><FontAwesomeIcon icon={faInstagram} className="icon" />Follow me on Instagram</a>
                        </Col>
                        <Col xs={12} lg={12}>
                            <div className="sm w-100 d-flex justify-content-center mt-3 j-">
                                <a href="https://www.facebook.com/lucamusicuk" className="social-link social-link-small"><FontAwesomeIcon icon={faFacebookSquare} /></a>
                                <a href="https://twitter.com/lucamusicuk" className="social-link social-link-small"><FontAwesomeIcon icon={faTwitterSquare} /></a>
                                {/* <a href="#" className="social-link social-link-small"><FontAwesomeIcon icon={faSnapchatSquare} /></a> */}
                                {/* <a href="#" className="social-link"><FontAwesomeIcon icon={faInstagramSquare} /></a> */}
                                <a href="https://www.youtube.com/@lucamusicuk" className="social-link"><FontAwesomeIcon icon={faYoutubeSquare} /></a>
                                <a href="mailto:lucamusicuk@gmail.com" className="social-link social-link-small"><FontAwesomeIcon icon={faEnvelopeSquare} /></a>
                                <a href="https://www.tiktok.com/@lucamusicuk" className="social-link social-link-small"><FontAwesomeIcon icon={faTiktok} /></a>
                            </div>
                        </Col>
                    </Row>
                </div>

                {/* Hire Luca */}
                <div className="w-100">
                    <a href="mailto:bookluca@gmail.com" className="standard-btn custom-colour-1  mt-3 w-100">Hire Luca for your event</a>
                </div>
                {/* Hire Luca */}
                <div className="w-100">
                    <Button variant='secondary' className="button w-100 mt-3" onClick={handleShow}>💌 Join My Inner Circle! 🔑</Button>
                </div>



                <Formik
                    initialValues={{ email: '', message: '' }}
                    validate={values => {
                        const errors = {};
                        if (!values.email) errors.email = 'Required';
                        else if (!values.message) errors.message = 'Required'
                        else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
                            errors.email = 'Invalid email address';
                        }
                        return errors;
                    }}
                    onSubmit={(values, { setSubmitting }) => {
                        setSubmitting(true)
                        fetch('https://nlpo4mx0w1.execute-api.us-east-1.amazonaws.com/dev/email', {
                            method: 'POST',
                            headers: { 'Content-Type': 'application/json' },
                            body: JSON.stringify({
                                email: values.email,
                                message: values.message
                            })
                        }).then(data => {
                            console.log(data);
                            if (data['status'] == 200) submitted = true;
                        }, err => {
                            console.log(err)
                        }).finally(() => setSubmitting(false))
                    }}
                >
                    {({ values, errors, touched, handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                    }) => (
                        <form className="form mt-5 mb-2 px-3" onSubmit={handleSubmit}>
                            <p className="send-message-text">Send a message to Luca</p>
                            <Form.Group className="mb-3" controlId="email">
                                <Form.Control type="email" placeholder="Enter your email" onChange={handleChange} onBlur={handleBlur} value={values.email} disabled={submitted} />
                                <div className="errors">{errors.email && touched.email && errors.email}</div>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="message">
                                <Form.Control as="textarea" rows={3} placeholder="Enter your message" onChange={handleChange} onBlur={handleBlur} value={values.message} disabled={submitted} />
                                <div className="errors">{errors.message && touched.message && errors.message}</div>
                            </Form.Group>
                            <button className='btn btn-primary' type="submit" disabled={isSubmitting || submitted}>
                                Submit
                            </button>
                            <div className="submitted">{submitted ? <p className='messageSent'>Your message has been sent.</p> : null}</div>
                        </form>


                    )}
                </Formik>
            </div >

            <div className="social-container">
                <a href="https://www.facebook.com/lucamusicuk" className="social-link"><FontAwesomeIcon icon={faFacebookSquare} /></a>
                <a href="https://twitter.com/lucamusicuk" className="social-link"><FontAwesomeIcon icon={faTwitterSquare} /></a>
                <a href="https://instagram.com/lucamusicuk" className="social-link"><FontAwesomeIcon icon={faInstagramSquare} /></a>
                <a href="https://www.youtube.com/@lucamusicuk" className="social-link"><FontAwesomeIcon icon={faYoutubeSquare} /></a>
                <a href="mailto:messagelucamusicuk@gmail.com" className="social-link"><FontAwesomeIcon icon={faEnvelopeSquare} /></a>
                <a href="https://www.tiktok.com/@lucamusicuk" className="social-link"><FontAwesomeIcon icon={faTiktok} /></a>
            </div>
            <Modal show={showMailingListModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Join My Inner Circle! 🔑</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="d-flex flex-column">
                        <div className="modal-header-1"> 💌 Subscribe to Luca's Mailing List 💌</div>
                        <div className="mt-2">While Instagram and Facebook are fantastic for staying connected and sharing content, they have limitations when it comes to reaching all my fans. By joining my mailing list, you'll get exclusive updates, concert announcements, and new music releases directly in your inbox. Rest assured, I respect your inbox. I'll only contact you when there's something truly important happening regarding what you've selected. No spammy emails here!</div>
                    </div>
                    <Formik
                        initialValues={{ name: '', email: '', whereAreYouFrom: '', yearOfBirth: '', cityOfResidence: '', interestedInLucaGigs: false, newMusicAlerts: false }}
                        validate={values => {
                            const errors = {};
                            if (!values.name) errors.name = 'Your name is required';
                            if (!values.email) errors.email = 'Your email address is required.';
                            if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
                                errors.email = 'Please provide a valid email address.';
                            }
                            return errors;
                        }}
                        onSubmit={(values, { setSubmitting }) => {
                            setSubmitting(true)
                            fetch('https://h3zfv777oj.execute-api.eu-west-1.amazonaws.com/production/luca/mailing-list', {
                                method: 'POST',
                                headers: { 'Content-Type': 'application/json' },
                                body: JSON.stringify({
                                    email: values.email,
                                    name: values.name,
                                    whereAreYouFrom: values.whereAreYouFrom,
                                    yearOfBirth: values.yearOfBirth,
                                    cityOfResidence: values.cityOfResidence,
                                    interestedInLucaGigs: values.interestedInLucaGigs,
                                    newMusicAlerts: values.newMusicAlerts,
                                })
                            }).then(data => {
                                console.log(data);
                                if (data['status'] === 200) {
                                    submitted = true;
                                    handleClose();
                                    alert("Succesfully signed up to mailing list!");
                                } else {
                                    alert("There was an error signing up to the mailing list. Please try again later.")
                                }
                               
                            }, err => {
                                console.log(err)
                            }).finally(() => setSubmitting(false))
                        }}
                    >
                        {({ values, errors, touched, handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,
                        }) => (
                            <form className="form mt-3 mb-2" onSubmit={handleSubmit}>
                                <Form.Group className="mb-2" controlId="name">
                                    <Form.Label className="form-label">What is your name?</Form.Label>
                                    <Form.Control type="text" placeholder="Name" onChange={handleChange} onBlur={handleBlur} value={values.name} disabled={submitted} />
                                    <div className="errors-2">{errors.name && touched.name && errors.name}</div>
                                </Form.Group>
                                <Form.Group className="mb-2" controlId="email">
                                    <Form.Label className="form-label">What is your email address?</Form.Label>
                                    <Form.Control type="email" placeholder="Email Address" onChange={handleChange} onBlur={handleBlur} value={values.email} disabled={submitted} />
                                    <div className="errors-2">{errors.email && touched.email && errors.email}</div>
                                </Form.Group>
                                <Form.Group className="mb-2" controlId="whereAreYouFrom">
                                    <Form.Label className="form-label">Where are you from? 🌍</Form.Label>
                                    <Form.Control type="text" placeholder="Optional" onChange={handleChange} onBlur={handleBlur} value={values.whereAreYouFrom} disabled={submitted} />
                                </Form.Group>
                                <Form.Group className="mb-2" controlId="yearOfBirth">
                                    <Form.Label className="form-label">Where year were you born? 🗓 <br /><span className="exp">(Help me get to know my diverse fan base!)</span></Form.Label>
                                    <Form.Control type="text" placeholder="Optional" onChange={handleChange} onBlur={handleBlur} value={values.yearOfBirth} disabled={submitted} />
                                </Form.Group>

                                <Form.Group className="mb-2" controlId="cityOfResidence">
                                    <Form.Label className="form-label">Which city do you live in? 🏙️ <br /><span className="exp">(I'll inform you when I'm performing near you!)</span></Form.Label>
                                    <Form.Control type="text" placeholder="Optional" onChange={handleChange} onBlur={handleBlur} value={values.cityOfResidence} disabled={submitted} />
                                </Form.Group>
                                <Form.Group className="mb-2" controlId="interestedInLucaGigs">
                                    <Form.Label className="form-label">Interested in Luca's London Gigs? 🎤<br /><span className="exp">(Be the first to know about my London events!)</span></Form.Label>
                                    <Form.Check type="checkbox" placeholder="Optional" onChange={handleChange} onBlur={handleBlur} value={values.interestedInLucaGigs} disabled={submitted} ></Form.Check>
                                </Form.Group>
                                <Form.Group className="mb-2" controlId="newMusicAlerts">
                                    <Form.Label className="form-label">New Music Alerts 🎶<br /><span className="exp">(Get notified when I drop a new track!)</span></Form.Label>
                                    <Form.Check type="checkbox" placeholder="Optional" onChange={handleChange} onBlur={handleBlur} value={values.newMusicAlerts} disabled={submitted} ></Form.Check>
                                </Form.Group>



                                <div className="mb-3">📨 Fill in as many boxes as you like. Your privacy matters to me, and your information will only be used to keep you in the loop about my music journey.
                                    Thank you for being a part of my musical adventure! 🎵✨</div>
                               

                                    <Button variant="primary" type="submit" onClick={handleSubmit}>
                                        Submit
                                    </Button>
                            </form>


                        )}
                    </Formik>
                </Modal.Body>

            </Modal>
        </div >
    )
}

export default CoventGarden;