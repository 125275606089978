/* Import Stylesheet */
import "./Home.css";

/* Import Modules */
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookSquare, faTwitterSquare, faTiktok, faSnapchatSquare, faYoutube, faYoutubeSquare, faInstagramSquare, faInstagram } from '@fortawesome/free-brands-svg-icons'
import { faAddressBook, faAddressCard, faEnvelope, faMoneyBillAlt } from "@fortawesome/free-regular-svg-icons"
import { faEnvelopeSquare, faMailBulk, faMoneyBillWave, faMoneyBillWaveAlt } from "@fortawesome/free-solid-svg-icons";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Formik } from 'formik';

function Home() {

    let submitted = false;
    return (
        <div className="page-container">

            <div></div>

            <div class="center-container">

                {/* Title */}
                <div className="heading">LUCA<span className="highlight">MUSIC</span>UK</div>

                {/* Links */}
                {/* <div className="links-container px-3">
                    <a href='https://buy.stripe.com/7sIaFr6WHbVUcJW289' className="standard-btn"><FontAwesomeIcon icon={faMoneyBillWave} className="icon" />Donate £5</a>
                    <a href='https://buy.stripe.com/bIYfZLgxhaRQ25ieUW' to="contact" className="standard-btn"><FontAwesomeIcon icon={faMoneyBillWave} className="icon" />Donate £10</a>
                    <a href='https://buy.stripe.com/4gwaFrep9e429xKbIL' className="standard-btn"><FontAwesomeIcon icon={faMoneyBillWave} className="icon" />Donate £20</a>
                </div> */}

                {/* Instagram */}
                <div className="px-3 w-100">
                    <a href="https://instagram.com/lucamusicuk" className="standard-btn standard-btn-instagram mt-3 w-100"><FontAwesomeIcon icon={faInstagram} className="icon" />Follow me on Instagram</a>
                </div>

                {/* Social Media */}
                <div className="px-3 w-100">
                    <div className="sm w-100 d-flex justify-content-center mt-3">
                        <a href="https://www.facebook.com/lucamusicuk" className="social-link"><FontAwesomeIcon icon={faFacebookSquare} /></a>
                        <a href="https://twitter.com/lucamusicuk" className="social-link"><FontAwesomeIcon icon={faTwitterSquare} /></a>
                        {/* <a href="#" className="social-link"><FontAwesomeIcon icon={faInstagramSquare} /></a> */}
                        <a href="https://www.youtube.com/@lucamusicuk" className="social-link"><FontAwesomeIcon icon={faYoutubeSquare} /></a>
                        <a href="mailto:lucamusicuk@gmail.com" className="social-link"><FontAwesomeIcon icon={faEnvelopeSquare} /></a>
                        <a href="https://www.tiktok.com/@lucamusicuk" className="social-link"><FontAwesomeIcon icon={faTiktok} /></a>
                    </div>
                </div>

                {/* Hire Luca */}
                {/* <div className="px-3 w-100">
                    <a href="mailto:lucamusicuk@gmail.com" className="standard-btn  mt-3 w-100">Hire Luca for your event</a>
                </div> */}


                <Formik
                    initialValues={{ email: '', message: '' }}
                    validate={values => {
                        const errors = {};
                        if (!values.email) errors.email = 'Required';
                        else if (!values.message) errors.message = 'Required'
                        else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
                            errors.email = 'Invalid email address';
                        }
                        return errors;
                    }}
                    onSubmit={(values, { setSubmitting }) => {
                        setSubmitting(true)
                        fetch('https://nlpo4mx0w1.execute-api.us-east-1.amazonaws.com/dev/email', {
                            method: 'POST',
                            headers: { 'Content-Type': 'application/json' },
                            body: JSON.stringify({ 
                                email: values.email,
                                message: values.message
                            })
                        }).then(data => {
                            console.log(data);
                            if (data['status'] == 200) submitted = true;
                        }, err => {
                            console.log(err)
                        }).finally(() => setSubmitting(false))
                    }}
                >
                    {({ values,errors,touched,handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                    }) => (
                        <form class="form mt-5 mb-2 px-3" onSubmit={handleSubmit}>
                                <p class="send-message-text">Send a message to Luca</p>
                                <Form.Group className="mb-3" controlId="email">
                                    <Form.Control type="email" placeholder="Enter your email" onChange={handleChange} onBlur={handleBlur} value={values.email} disabled={submitted}/>
                                    <div className="errors">{errors.email && touched.email && errors.email}</div>
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="message">
                                    <Form.Control as="textarea" rows={3} placeholder="Enter your message" onChange={handleChange} onBlur={handleBlur} value={values.message} disabled={submitted}/>
                                    <div className="errors">{errors.message && touched.message && errors.message}</div>
                                </Form.Group>
                                <button class='btn btn-primary' type="submit" disabled={isSubmitting || submitted}>
                                    Submit
                                </button>
                                <div className="submitted">{ submitted ? <p className='messageSent'>Your message has been sent.</p> : null }</div>
                        </form>


                    )}
                </Formik>
            </div >
            <div className="social-container">
                <a href="https://www.facebook.com/lucamusicuk" className="social-link"><FontAwesomeIcon icon={faFacebookSquare} /></a>
                <a href="https://twitter.com/lucamusicuk" className="social-link"><FontAwesomeIcon icon={faTwitterSquare} /></a>
                <a href="https://instagram.com/lucamusicuk" className="social-link"><FontAwesomeIcon icon={faInstagramSquare} /></a>
                <a href="https://www.youtube.com/@lucamusicuk" className="social-link"><FontAwesomeIcon icon={faYoutubeSquare} /></a>
                <a href="mailto:messagelucamusicuk@gmail.com" className="social-link"><FontAwesomeIcon icon={faEnvelopeSquare} /></a>
                <a href="https://www.tiktok.com/@lucamusicuk" className="social-link"><FontAwesomeIcon icon={faTiktok} /></a>
            </div>
        </div >
    )
}

export default Home;